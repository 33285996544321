import { DefaultTheme } from 'styled-components/macro';
import { ThemeName } from '../types';

const colors = {
  bgColor: '#f5f5f5',
  darkBlue: '#2e3d5c',
  lightBlue: '#f1f3f6',
  gray: '#959dad',
  lightGray: '#b6bbc4',
  white: '#ffffff',
  red: '#e55457',
  orange: '#FF8800',
  yellow: '#ffb500',
  green: '#77a846',
  saunaBackground: '#E6E7E8',
  saunaDarkBlue: '#384F63',
  saunaLightBlue: '#B0CCD9',
  saunaBeige: '#B39D90',
};

const fonts = {
  fontHeading: 'GearedSlab-Bold',
  fontRegular: 'Lato-Regular',
  fontMedium: 'Lato-Medium',
  fontBold: 'Lato-Bold',
};

export const neptune: DefaultTheme = {
  name: ThemeName.Neptune,
  capitalizeHeading: true,
  colors: {
    summaryBackgroundColor: colors.darkBlue,
    header: {
      mobile: {
        backgroundColor: colors.darkBlue,
      },
      desktop: {
        backgroundColor: colors.darkBlue,
      },
    },
    primary: colors.darkBlue,
    secondary: colors.yellow,
    bookingTimer: {
      backgroundColor: colors.lightBlue,
    },
    buttons: {
      primary: {
        backgroundColor: colors.darkBlue,
        textColor: colors.white,
      },
      secondary: {
        backgroundColor: colors.darkBlue,
        textColor: colors.white,
      },
      highlighted: {
        backgroundColor: colors.orange,
        textColor: colors.white,
      },
      disabled: {
        backgroundColor: colors.gray,
        textColor: colors.white,
      },
    },
    cancellationWarranty: {
      buttonBackgroundColor: colors.darkBlue,
      buttonTextColor: colors.white,
      backgroundColor: colors.lightBlue,
      textColor: colors.darkBlue,
      headingColor: colors.darkBlue,
    },
    modals: {
      backgroundColor: colors.white,
    },
    uspBanner: {
      backgroundColor: colors.darkBlue,
      textColor: colors.white,
    },
    timeslots: {
      selected: {
        backgroundColor: colors.yellow,
        textColor: colors.darkBlue,
      },
    },
  },
  header: {
    height: { desktop: '85px', mobile: '55px' },
  },
  buttons: {
    borderRadius: '4px',
  },
  inputs: {
    checkboxBorderRadius: '3px',
    borderRadius: '4px',
  },
  shadows: true,
  bgColor: colors.bgColor,
  highLightColor: colors.yellow,
  textColor: colors.darkBlue,
  subTextColor: colors.gray,

  inputBackgroundColor: colors.white,
  inputBorderColor: colors.lightGray,
  inputTextColor: colors.darkBlue,
  inputPlaceholderColor: colors.gray,
  inputLabelColor: colors.darkBlue,
  inputBorderColorFocus: colors.darkBlue,

  errorColor: colors.red,
  fontText: fonts.fontRegular,
  fontTextBold: fonts.fontBold,
  fontLink: fonts.fontMedium,
  fontLabel: fonts.fontMedium,
  fontSubLabel: fonts.fontRegular,
  fontHeading: fonts.fontHeading,
  fontHeading2: fonts.fontMedium,
  fontButton: fonts.fontBold,
  fontSegmentedButton: fonts.fontBold,
  fontLinkButton: fonts.fontMedium,
  fontInput: fonts.fontRegular,
  stepBorder:
    'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjUgMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzJlM2Q1YyIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEwKSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMCkiLz48Y2lyY2xlIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAgMjApIi8+PGNpcmNsZSBjeD0iMi41IiBjeT0iMi41IiByPSIyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwKSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMCAyMCkiLz48Y2lyY2xlIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAgMTApIi8+PC9nPjwvc3ZnPg==',
};
