import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  GetShopQuery,
  GetUspBannerEntriesQuery,
  SupportedLanguage,
} from '../Utils/graphql-sdk';
import { stateContext } from './State';
import { graphqlSdk } from '../Utils/graphql-client';
import useLocale from '../Hooks/use-locale';

interface ShopContextValue {
  loading: boolean;
  shop?: GetShopQuery['getShop'];
  uspBannerEntries: GetUspBannerEntriesQuery['getUspBannerEntries'];
}

const defaultContextValue: ShopContextValue = {
  loading: false,
  shop: null,
  uspBannerEntries: [],
};

export const ShopContext = createContext(defaultContextValue);

interface ShopContextProviderProps {
  children: React.ReactNode;
}

const ShopContextProvider = (props: ShopContextProviderProps) => {
  const { state } = useContext(stateContext);
  const [loading, setLoading] = useState(false);
  const [shop, setShop] = useState<ShopContextValue['shop']>(null);
  const { languageAsEnum: language } = useLocale();
  const [uspBannerEntries, setUspBannerEntries] = useState<
    ShopContextValue['uspBannerEntries']
  >([]);

  const getShop = async (shopId: string) => {
    setLoading(true);
    setShop(null);
    const shop = await graphqlSdk.getShop({ shopId });
    if (shop.getShop?.id === state.shop.id) {
      setLoading(false);
      setShop(shop.getShop);
    }
  };

  const getUspBannerEntries = async (shopId: string, language: SupportedLanguage) => {
    setLoading(true);
    setUspBannerEntries([]);
    const result = await graphqlSdk.getUspBannerEntries({
      shopId,
      language,
    });

    if (
      result.getUspBannerEntries.some((entry) => {
        return entry.shopId === state.shop.id;
      })
    ) {
      setLoading(false);
      setUspBannerEntries(result.getUspBannerEntries);
    }
  };

  useEffect(() => {
    if (state.shop?.id) {
      getShop(state.shop?.id);
      getUspBannerEntries(state.shop?.id, language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.shop]);

  const value: ShopContextValue = {
    loading,
    shop,
    uspBannerEntries,
  };

  return <ShopContext.Provider value={value}>{props.children}</ShopContext.Provider>;
};

export default ShopContextProvider;
