const initialState = null;

const selecetedDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SELECTED_DATE_UPDATE_DATE': {
      return action.selectedDate;
    }
    case 'SHOP_UPDATE_SHOP': {
      return null;
    }
    default:
      return state;
  }
};

export default selecetedDateReducer;
