const initialState = {
  termsChecked: false,
  newsletterSubscribed: null,
};

const termsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TERMS_UPDATE_TERMS_CHECKED': {
      return {
        ...state,
        termsChecked: action.checked,
      };
    }
    case 'TERMS_UPDATE_NEWSLETTER_SUBSCRIBED': {
      return {
        ...state,
        newsletterSubscribed: action.subscribed,
      };
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS':
    case 'SHOP_FETCH_SHOP_SUCCESS_KEEP_BOOKING': {
      // If shop don't have newsletter, set subscribed to a valid value
      let subscribed = action.shop.mailchimp ? null : false;

      return {
        ...state,
        newsletterSubscribed: subscribed,
      };
    }
    default:
      return state;
  }
};

export default termsReducer;
