const initialState = {
  applied: false,
  price: 0,
};

const warrantyReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'WARRANTY_UPDATE': {
      return action.warranty;
    }
    case 'WARRANTY_UPDATE_APPLIED': {
      return {
        ...state,
        applied: action.applied,
      };
    }
    case 'WARRANTY_UPDATE_PRICE': {
      return {
        ...state,
        price: action.price,
      };
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.warranty) return action.warranty;

      return state;
    }
    default:
      return state;
  }
};

export default warrantyReducer;
