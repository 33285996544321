import { DefaultTheme } from 'styled-components/macro';
import { ThemeName } from '../types';

const colors = {
  starboardBlue: '#44525F',
  starboardYellow: '#FFDF63',
  bgGrey: '#F5F6F7',
  white: '#ffffff',
  textColor: '#2E3D5C',
  darkGray: '#1F1F1F',
  mediumGray: '#959dad',
  lightGray: '#b6bbc4',
  orange: '#FF8800',
  red: '#e55457',
};

const fonts = {
  fontHeading: 'Faro-Bold',
  fontRegular: 'Faro-Regular',
  fontMedium: 'Faro-Medium',
  fontBold: 'Faro-Bold',
};

export const starboard: DefaultTheme = {
  name: ThemeName.Starboard,
  capitalizeHeading: false,
  colors: {
    header: {
      mobile: {
        backgroundColor: colors.starboardBlue,
      },
      desktop: {
        backgroundColor: colors.starboardBlue,
      },
    },
    summaryBackgroundColor: colors.starboardBlue,
    primary: colors.starboardBlue,
    secondary: colors.starboardYellow,
    bookingTimer: {
      backgroundColor: colors.white,
    },
    buttons: {
      primary: {
        backgroundColor: colors.starboardBlue,
        textColor: colors.white,
      },
      secondary: {
        backgroundColor: colors.starboardYellow,
        textColor: colors.starboardBlue,
      },
      highlighted: {
        backgroundColor: colors.orange,
        textColor: colors.white,
      },
      disabled: {
        backgroundColor: colors.mediumGray,
        textColor: colors.white,
      },
    },
    cancellationWarranty: {
      buttonBackgroundColor: colors.bgGrey,
      buttonTextColor: colors.starboardBlue,
      backgroundColor: colors.starboardBlue,
      textColor: colors.white,
      headingColor: colors.starboardYellow,
    },
    modals: {
      backgroundColor: colors.bgGrey,
    },
    uspBanner: {
      backgroundColor: colors.starboardBlue,
      textColor: colors.white,
    },
    timeslots: {
      selected: {
        backgroundColor: colors.starboardBlue,
        textColor: colors.white,
      },
    },
  },

  header: {
    // height: { desktop: '120px', mobile: '120px' },
    height: { desktop: '85px', mobile: '55px' },
  },
  buttons: {
    borderRadius: '75px',
  },
  inputs: {
    checkboxBorderRadius: '3px',
    borderRadius: '20px',
  },
  shadows: false,
  bgColor: colors.bgGrey,
  highLightColor: colors.starboardYellow,
  textColor: colors.textColor,
  subTextColor: colors.mediumGray,

  inputBackgroundColor: colors.white,
  inputBorderColor: colors.lightGray,
  inputTextColor: colors.starboardBlue,
  inputPlaceholderColor: colors.mediumGray,
  inputLabelColor: colors.starboardBlue,
  inputBorderColorFocus: colors.starboardBlue,

  errorColor: colors.red,
  fontText: fonts.fontRegular,
  fontTextBold: fonts.fontBold,
  fontLink: fonts.fontMedium,
  fontLabel: fonts.fontMedium,
  fontSubLabel: fonts.fontRegular,
  fontHeading: fonts.fontHeading,
  fontHeading2: fonts.fontMedium,
  fontButton: fonts.fontRegular,
  fontSegmentedButton: fonts.fontBold,
  fontLinkButton: fonts.fontMedium,
  fontInput: fonts.fontRegular,
  stepBorder:
    'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjUgMjUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzJlM2Q1YyIgZmlsbC1ydWxlPSJldmVub2RkIj48Y2lyY2xlIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDIwKSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEwKSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMCkiLz48Y2lyY2xlIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTAgMjApIi8+PGNpcmNsZSBjeD0iMi41IiBjeT0iMi41IiByPSIyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIwKSIvPjxjaXJjbGUgY3g9IjIuNSIgY3k9IjIuNSIgcj0iMi41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMCAyMCkiLz48Y2lyY2xlIGN4PSIyLjUiIGN5PSIyLjUiIHI9IjIuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjAgMTApIi8+PC9nPjwvc3ZnPg==',
};
