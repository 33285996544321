const initialState = {
  name: '',
  formValid: false,
};

const recipientReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RECIPIENT_UPDATE_NAME': {
      return {
        ...state,
        name: action.name,
      };
    }
    case 'RECIPIENT_UPDATE_FORM_VALID': {
      return {
        ...state,
        formValid: action.formValid,
      };
    }
    default: {
      return state;
    }
  }
};

export default recipientReducer;
