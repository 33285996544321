const initialState = {
  isOpen: false,
  isLoading: false,
  isCanceled: false,
};

const cancellationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CANCELLATION_UPDATE_IS_OPEN': {
      if (!state.isCanceled) {
        return {
          ...state,
          isOpen: action.isOpen,
        };
      }

      return state;
    }
    case 'CANCELLATION_UPDATE_IS_LOADING': {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case 'CANCELLATION_UPDATE_IS_CANCELED': {
      return {
        ...state,
        isCanceled: action.isCanceled,
      };
    }
    default: {
      return state;
    }
  }
};

export default cancellationReducer;
