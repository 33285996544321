import React, { createContext, useReducer } from 'react';
import reducer from '../Reducers/rootReducer';

// create state context
export const stateContext = createContext();

const State = ({ children }) => {
  // create state reducer
  const [state, dispatch] = useReducer(reducer, undefined, reducer);

  // return context provider with values of state reducer
  return (
    <stateContext.Provider value={{ state, dispatch }}>{children}</stateContext.Provider>
  );
};

export default State;
