import React from 'react';
import { ModalProvider } from 'styled-react-modal';

import './Style/GlobalStyles.css';
import { ModalBackground } from './SubComponents/Modals';
import State from './Contexts/State';
import StripeContextProvider from './Contexts/stripe-context';
import TimeslotsContextProvider from './Contexts/timeslots-context';
import DurationOptionsProvider from './Contexts/duration-options-context';
import * as Tooltip from '@radix-ui/react-tooltip';
import ShopContextProvider from './Contexts/shop-context';
import queryString from 'query-string';
import { starboard } from './Style/themes/starboard';
import { neptune } from './Style/themes/neptune';
import { ThemeProvider } from 'styled-components/macro';

interface ProvidersProps {
  children: React.ReactNode;
}

const Providers = (props: ProvidersProps) => {
  const params = queryString.parse(window.location.search);
  const theme = params.theme === 'neptune' ? neptune : starboard;

  return (
    <ThemeProvider theme={theme}>
      <ModalProvider backgroundComponent={ModalBackground}>
        <State>
          <ShopContextProvider>
            <StripeContextProvider>
              <TimeslotsContextProvider>
                <DurationOptionsProvider>
                  <Tooltip.Provider>{props.children}</Tooltip.Provider>
                </DurationOptionsProvider>
              </TimeslotsContextProvider>
            </StripeContextProvider>
          </ShopContextProvider>
        </State>
      </ModalProvider>
    </ThemeProvider>
  );
};

export default Providers;
