const initialState = {};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * Booking loading states
     */
    case 'ERROR_BOOKING_CREATE':
    case 'BOOKING_UPDATE_BOOKING': {
      return {
        ...state,
        booking: false,
      };
    }
    case 'BOOKING_UPDATE_INITIATE':
    case 'BOOKING_FETCH_BOOKING_LOADING': {
      return {
        ...state,
        booking: true,
      };
    }
    /**
     * Shop loading states
     */
    case 'SHOP_FETCH_SHOP_LOADING': {
      return {
        ...state,
        shop: true,
      };
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS':
    case 'SHOP_FETCH_SHOP_ERROR':
    case 'SHOP_FETCH_SHOP_SUCCESS_KEEP_BOOKING': {
      return {
        ...state,
        shop: false,
      };
    }
    /**
     * Customer loading states
     */
    case 'CUSTOMER_UPDATE_INITIATE': {
      return {
        ...state,
        customer: true,
      };
    }
    case 'CUSTOMER_UPDATE_CUSTOMER': {
      return {
        ...state,
        customer: false,
      };
    }

    /**
     * Voucher loading states
     */
    case 'VOUCHERS_LOADING': {
      return {
        ...state,
        voucher: true,
      };
    }
    case 'ERROR_VOUCHER':
    case 'VOUCHERS_UPDATE': {
      return {
        ...state,
        voucher: false,
      };
    }

    default:
      return state;
  }
};

export default loadingReducer;
