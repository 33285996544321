import { GraphQLClient } from 'graphql-request';
import { getSdk } from './graphql-sdk';
import { v4 as uuidv4 } from 'uuid';

const ENDPOINT = '/api/v2/graphql';

let sessionId = sessionStorage.getItem('x-session-id');

if (sessionId === null) {
  const uuid = uuidv4();
  sessionId = uuid;
  sessionStorage.setItem('x-session-id', uuid);
}

const client = new GraphQLClient(ENDPOINT, {
  headers: {
    'x-client-name': 'booking-frontend',
    'x-session-id': sessionId,
  },
});

export const graphqlSdk = getSdk(client);
