import { getPageType } from '../Utils/utils';

const initialState = {
  prices: {},
  priceModel: {},
};

const pageType = getPageType();

const pricesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PRICES_UPDATE_PRICES': {
      return {
        ...state,
        prices: action.prices,
      };
    }
    case 'PRICES_UPDATE_PRICE_MODEL': {
      return {
        ...state,
        priceModel: action.priceModel,
      };
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS': {
      // Only set priceModel if on the giftcard page
      if (pageType === 'giftcard') {
        const giftcardModel = action.shop?.priceModels?.find((model) => model.giftcards);

        return { ...state, priceModel: giftcardModel };
      }

      return state;
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.priceModel) {
        return {
          ...state,
          priceModel: action.priceModel,
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default pricesReducer;
