import { useContext } from 'react';
import { stateContext } from '../Contexts/State';
import countryList from 'country-list';
import { SupportedLanguage } from '../Utils/graphql-sdk';

countryList.overwrite([
  {
    code: 'US',
    name: 'United States',
  },
]);

const supportedLanguages = Object.values(SupportedLanguage).map((value) => {
  return String(value).toLowerCase();
});

export function isSupportedLanguage(string: string): string is SupportedLanguage {
  return supportedLanguages.includes(string.toLowerCase());
}

type SupportedLanguageUnionType = `${SupportedLanguage}`;

export function isSupportedLanguageUnionType(
  string: string
): string is SupportedLanguageUnionType {
  return supportedLanguages.includes(string.toLowerCase());
}

const useLocale = () => {
  const { state } = useContext(stateContext);

  const country: string | undefined = state.shop?.country;
  const stateLanguage = String(state.locale.lang);

  const languageAsEnum = isSupportedLanguage(stateLanguage)
    ? stateLanguage
    : SupportedLanguage.En;

  const language = isSupportedLanguageUnionType(stateLanguage) ? stateLanguage : 'en';

  const countryCode = countryList.getCode(String(country));
  const locale = countryCode ? `${language}-${countryCode}` : language;

  return { locale, countryCode, language, languageAsEnum };
};

export default useLocale;
