import bookingReducer from './bookingReducer';
import giftcardReducer from './giftcardReducer';
import membershipReducer from './membershipReducer';
import customerReducer from './customerReducer';
import shopsReducer from './shopsReducer';
import shopReducer from './shopReducer';
import selecetedDateReducer from './selectedDateReducer';
import pricesReducer from './pricesReducer';
import paymentReducer from './paymentReducer';
import categoriesReducer from './categoriesReducer';
import summaryReducer from './summaryReducer';
import stepsReducer from './stepsReducer';
import itemsReducer from './itemsReducer';
import orderReducer from './orderReducer';
import termsReducer from './termsReducer';
import errorReducer from './errorReducer';
import recipientReducer from './recipientReducer';
import vouchersReducer from './vouchersReducer';
import voucherReducer from './voucherReducer';
import cancellationReducer from './cancellationReducer';
import warrantyReducer from './warrantyReducer';
import loadingReducer from './loadingReducer';
import localeReducer from './localeReducer';

const combineReducers = (reducers) => {
  return (state = {}, action = {}) => {
    // This is the object we are going to return.
    const nextState = {};

    // Loop through all the reducers
    for (const [key, reducer] of Object.entries(reducers)) {
      // Get the the previous state
      const stateForKey = state[key];
      // Get the next state by running the reducer
      const nextStateForKey = reducer(stateForKey, action);
      // Update the new state for the current reducer
      nextState[key] = nextStateForKey;
    }

    return nextState;
  };
};

const rootReducer = combineReducers({
  booking: bookingReducer,
  giftcard: giftcardReducer,
  membership: membershipReducer,
  customer: customerReducer,
  shops: shopsReducer,
  shop: shopReducer,
  selectedDate: selecetedDateReducer,
  prices: pricesReducer,
  payment: paymentReducer,
  categories: categoriesReducer,
  summary: summaryReducer,
  steps: stepsReducer,
  items: itemsReducer,
  order: orderReducer,
  terms: termsReducer,
  errors: errorReducer,
  recipient: recipientReducer,
  vouchers: vouchersReducer,
  voucher: voucherReducer,
  cancellation: cancellationReducer,
  warranty: warrantyReducer,
  loading: loadingReducer,
  locale: localeReducer,
});

export default rootReducer;
