import React, { useContext, useEffect, useState } from 'react';
import { Stripe, StripeConstructorOptions, loadStripe } from '@stripe/stripe-js';

import { getConfig } from '../Utils/utils';
import { ShopContext } from './shop-context';
import useLocale from '../Hooks/use-locale';

interface StripeProps {
  children: React.ReactNode;
}

interface StripeContextValue {
  initialized: boolean;
  stripe: Awaited<ReturnType<typeof loadStripe>> | null;
}

export const StripeContext = React.createContext<StripeContextValue>({
  initialized: false,
  stripe: null,
});

const StripeContextProvider = (props: StripeProps) => {
  const shopContext = useContext(ShopContext);
  const { language } = useLocale();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    async function getStripeInstance() {
      if (shopContext.shop?.id && language) {
        const stripeOptions: StripeConstructorOptions = {
          locale: language,
        };
        if (!shopContext.shop.main && shopContext.shop.configuration?.stripePublicKey) {
          stripeOptions.stripeAccount = shopContext.shop.configuration.stripePublicKey;
        }

        const stripe = await loadStripe(String(getConfig().stripeApiKey), stripeOptions);
        setStripe(stripe);
      }
    }
    getStripeInstance();
  }, [shopContext.shop, language]);

  const contextValue: StripeContextValue = {
    stripe: stripe,
    initialized: Boolean(stripe),
  };

  return (
    <StripeContext.Provider value={contextValue}>{props.children}</StripeContext.Provider>
  );
};

export default StripeContextProvider;
