import React, { createContext, useState } from 'react';
import { GetTimeslotsQuery } from '../Utils/graphql-sdk';

// create state context
interface TimeslotsContextTimeslots {
  loading: boolean;
  error: any;
  timeslots: GetTimeslotsQuery['getTimeslots'][0][];
}

const defaultTimeslots: TimeslotsContextTimeslots = {
  loading: false,
  error: null,
  timeslots: [],
};

interface TimeslotContextValue {
  timeslots: TimeslotsContextTimeslots;
  dispatchTimeslots: React.Dispatch<React.SetStateAction<TimeslotsContextTimeslots>>;
}

const defaultContextValue: TimeslotContextValue = {
  timeslots: defaultTimeslots,
  dispatchTimeslots: () => null,
};

export const timeslotsContext = createContext(defaultContextValue);

interface TimeslotsContextProviderProps {
  children: React.ReactNode;
}

const TimeslotsContextProvider = ({ children }: TimeslotsContextProviderProps) => {
  const [timeslots, setTimeslots] = useState(defaultTimeslots);

  return (
    <timeslotsContext.Provider value={{ timeslots, dispatchTimeslots: setTimeslots }}>
      {children}
    </timeslotsContext.Provider>
  );
};

export default TimeslotsContextProvider;
