const initialState = {
  categories: [],
  current: null,
};

const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CATEGORIES_UPDATE_CATEGORIES': {
      return {
        ...state,
        categories: action.categories,
      };
    }
    case 'CATEGORIES_UPDATE_CURRENT': {
      return {
        ...state,
        current: action.current,
      };
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS':
    case 'SHOP_FETCH_SHOP_SUCCESS_KEEP_BOOKING': {
      let itemCategories = ['All'];
      if (action.shop.items) {
        for (const item of action.shop.items) {
          if (!item.archived) {
            if (!itemCategories.find((foundCategory) => foundCategory === item.category))
              itemCategories.push(item.category);
          }
        }
      }

      return {
        ...state,
        categories: itemCategories,
        current: itemCategories[0],
      };
    }
    default:
      return state;
  }
};

export default categoriesReducer;
