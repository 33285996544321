// GoogleTag.js
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { stateContext } from './Contexts/State';

// there were problems with the google tag for goboat italy
// they wanted to try adding the tag directly
// im skeptical but still did it
const ItalianGoogleTag = () => {
  const { state } = useContext(stateContext);

  if (
    window.location.hostname !== 'booking.goboat.it' &&
    (!state.shop || state.shop.country !== 'Italy')
  ) {
    return null;
  }

  return (
    <Helmet>
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16623411673" />
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16623411673');
          console.log('Buon giorno!');
        `}
      </script>
    </Helmet>
  );
};

export default ItalianGoogleTag;
