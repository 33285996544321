const initialState = [];

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ORDER_UPDATE_ORDER': {
      return action.order;
    }
    case 'ORDER_ADD_ITEM': {
      const updatedAddon = {
        itemId: action.item.id,
        quantity: action.item.count,
      };

      for (const addon of state) {
        if (addon.itemId === action.item.id) {
          updatedAddon.quantity = addon.quantity + action.item.count;

          const indexOfUpdatedAddon = state.findIndex(
            (existingAddon) => existingAddon.itemId === updatedAddon.itemId
          );

          return [
            ...state.slice(0, indexOfUpdatedAddon),
            updatedAddon,
            ...state.slice(indexOfUpdatedAddon + 1),
          ];
        }
      }

      return [...state, updatedAddon];
    }
    case 'ORDER_REMOVE_ITEM': {
      const indexOfAddon = state.findIndex(
        (existingAddon) => existingAddon.itemId === action.addon.itemId
      );

      return [...state.slice(0, indexOfAddon), ...state.slice(indexOfAddon + 1)];
    }
    case 'SHOP_UPDATE_SHOP': {
      return [];
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.order) return action.order;

      return state;
    }
    default:
      return state;
  }
};

export default orderReducer;
