const initialState = {
  openSummary: false,
};

const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUMMARY_UPDATE_SUMMARY_OPEN': {
      return {
        ...state,
        openSummary: action.open,
      };
    }
    default:
      return state;
  }
};

export default summaryReducer;
