const initialState = {
  id: '',
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS':
    case 'SHOP_FETCH_SHOP_SUCCESS_KEEP_BOOKING': {
      return action.shop;
    }
    case 'SHOP_FETCH_SHOP_ERROR': {
      return state;
    }
    default: {
      return state;
    }
  }
};

export default shopReducer;
