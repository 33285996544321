import { Duration } from 'luxon';

export const getConfig = () => {
  const config = {
    baseApi: '/api',
    stripeApiKey: process.env.REACT_APP_STRIPE_KEY,
    sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    sentryEnabled: process.env.REACT_APP_SENTRY_ENABLED,
    sentryRelease: process.env.REACT_APP_SENTRY_RELEASE,
  };

  return config;
};

export const hexToRgb = (hex, { opacity = 100 } = {}) => {
  if (
    (hex && [7, 4].includes(hex.length) && hex.indexOf('#') === 0) ||
    (hex && [6, 3].includes(hex.length) && hex.indexOf('#') === -1)
  ) {
    const modifyHex = (hex) => {
      if (hex.length === 4) {
        hex = hex.replace('#', '');
      }
      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      return hex;
    };

    let rgb = [];
    hex = hex.replace('#', '');

    if (hex.length !== 6) {
      hex = modifyHex(hex);
    }

    rgb.push(parseInt(hex.slice(0, 2), 16));
    rgb.push(parseInt(hex.slice(2, 4), 16));
    rgb.push(parseInt(hex.slice(4, 6), 16));

    opacity = opacity / 100;

    return `rgb(${rgb.toString()},${opacity})`;
  }
  console.warn(`hexToRgb: not converted - invalid argument (arg: ${hex})`);
  return hex;
};

export const setDocTitle = (shop) => {
  if (shop) return `Goboat Booking - ${shop}`;
  return `Goboat Booking`;
};

export const currentStepToPercentage = (currentStep, totalSteps) => {
  return (currentStep / totalSteps) * 100;
};

export const getDurationString = (duration) => {
  if (duration) {
    if (typeof duration === 'number' || !isNaN(Number(duration))) {
      return Duration.fromObject({ hours: duration }).toFormat('hh:mm');
    }

    // Don't show warnings in the console if duration is set to '-'
    if (typeof duration === 'string' && duration === '-') return duration;

    console.warn(
      `getDurationString: duration is not a number (type: ${typeof duration}, value: ${duration})`
    );

    return duration;
  }

  console.warn(`getDurationString: Duration is falsey (val: ${duration})`);
  return duration;
};

/**
 *
 * @param {string} durationString The duration as a string
 * @returns {number} The duration as a number
 *
 */
export const getDurationNumber = (durationString) => {
  if (durationString) return Duration.fromISOTime(durationString).as('hours');

  console.warn(`getDurationNumber: durationString is falsey (val: ${durationString})`);
  return durationString;
};

/**
 *
 * @param {array} timeslots list of timeslots received from server
 * @param {number} duration The duration in hours on which to filter
 * @returns {array} filtered timeslots
 *
 * This function will allow you to filter out all timeslots that don't align
 * with the closing time with regards to a specified duration
 * it is useful for instance, if you only allow 90 minute bookings
 * and you want to make sure that there are no 30 or 60 minute gaps
 * inbetween bookings
 */
export const filterTimeslotsBasedOnClosingTime = (timeslots, duration = 1.5) => {
  const durationMinutes = duration * 60;

  const [closingHours, closingMinutes] = timeslots[timeslots.length - 1][0]
    .split(':')
    .map(Number);

  const totalClosingMinutes = closingHours * 60 + closingMinutes + 15;

  return timeslots.filter((timeslot, index) => {
    const [hours, minutes] = timeslot[0].split(':').map(Number);

    const totalMinutes = hours * 60 + minutes;

    return totalMinutes % durationMinutes === totalClosingMinutes % durationMinutes;
  });
};

/**
 *
 * @param {number | string} hours the duration in hours. Fx. 1, 1.5 etc.
 * @param {object} options options object
 * @returns {string} formatted duration
 *
 * This function will allow you to format a duration.
 * Output format is fx.: 30m, 1h, 1h 30m
 */
export const formatDuration = (hours, { locale } = {}) => {
  if (Number.isNaN(Number(hours))) {
    return '-';
  }

  const duration = Duration.fromObject({ hours }).shiftTo('hours', 'minutes');

  const hourFull = locale?.localization.product.hour ?? 'hour';
  const hoursFull = locale?.localization.product.hours ?? 'hours';
  const minutesFull = locale?.localization.product.minutes ?? 'minutes';

  const hourAbbr = locale?.localization.product.hourAbbr ?? 'h';
  const minuteAbbr = locale?.localization.product.minuteAbbr ?? 'm';

  let format;

  if (hours < 1) format = `m '${minutesFull}'`;
  if (hours === 1) format = `h '${hourFull}'`;
  if (hours > 1) format = `h '${hoursFull}'`;
  if (hours >= 1 && duration.minutes) format = `h'${hourAbbr}' m'${minuteAbbr}'`;

  return duration.toFormat(format);
};

export const capitalize = (string) => string[0].toUpperCase() + string.slice(1);

/**
 * Checks a certain feature flag and reports true if it is enabled, false otherwise
 * @param {Object} shop Shop object
 * @param {string} featureName feature name
 * @returns {Boolean} true/false for given feature name, or false if feature does not exist
 */
export const checkFeatureFlag = (shop, featureName) => {
  try {
    return Boolean(
      shop?.featureFlags?.find((object) => object.featureName === featureName)?.enabled
    );
  } catch (error) {
    console.warn('error checking feature flag', error);
    return false;
  }
};

export const getPageType = () => {
  const pathname = window.location.pathname;

  if (/^\/booking/i.test(pathname)) return 'booking';
  if (/^\/giftcard/i.test(pathname)) return 'giftcard';
  if (/^\/goboat-plus/i.test(pathname)) return 'membership';
  if (/^\/sauna/i.test(pathname)) return 'sauna';

  return 'booking';
};
