import { createContext, useState } from 'react';

export interface DurationOption {
  priceLow: number;
  priceHigh: number;
  durationString: any;
  durationNumber: number;
}

const defaultDurationOptions: DurationOption[] = [];

interface DurationOptionsContextValue {
  durationOptions: DurationOption[];
  dispatchDurationOptions: React.Dispatch<React.SetStateAction<DurationOption[]>>;
}

const defaultContextValue: DurationOptionsContextValue = {
  durationOptions: defaultDurationOptions,
  dispatchDurationOptions: () => null,
};

export const durationOptionsContext = createContext(defaultContextValue);

interface DurationOptionsProviderProps {
  children: React.ReactNode;
}

const DurationOptionsProvider = ({ children }: DurationOptionsProviderProps) => {
  const [durationOptions, setDurationOptions] = useState(defaultDurationOptions);

  return (
    <durationOptionsContext.Provider
      value={{ durationOptions, dispatchDurationOptions: setDurationOptions }}
    >
      {children}
    </durationOptionsContext.Provider>
  );
};

export default DurationOptionsProvider;
