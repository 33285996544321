import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { stateContext } from './Contexts/State';

// there were problems with the google tag for goboat italy
// they wanted to try adding the tag directly
// im skeptical but still did it
const DkConvertTag = () => {
  const { state } = useContext(stateContext);

  if (
    window.location.hostname !== 'booking.goboat.dk' &&
    (!state.shop || state.shop.country !== 'Denmark')
  ) {
    return null;
  }

  return (
    <Helmet>
      <script
        type="text/javascript"
        src="//cdn-4.convertexperiments.com/js/10048264-10049931.js"
      />
      <script>
        {`
          console.log('Wienerbrod');
        `}
      </script>
    </Helmet>
  );
};

export default DkConvertTag;
