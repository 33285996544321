import React, { useContext } from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

import { stateContext } from './Contexts/State';

const Helmet = () => {
  const { state } = useContext(stateContext);

  return (
    <ReactHelmet titleTemplate="GoBoat Booking - %s">
      {state.shop.name && <title>{state.shop.name}</title>}
      {state.locale.localization.meta.description && (
        <meta name="description" content={state.locale.localization.meta.description} />
      )}
      {state.shop.id && state.shop.id === '5852d4ef20ff17366fcb6cf4' && (
        <meta name="robots" content="noindex" />
      )}
    </ReactHelmet>
  );
};

export default Helmet;
