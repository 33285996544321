const initialState = {
  plan: null,
};

const membershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'MEMBERSHIP_UPDATE_PLAN': {
      return {
        ...state,
        plan: action.plan,
      };
    }
    default: {
      return state;
    }
  }
};

export default membershipReducer;
