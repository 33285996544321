const initialState = [];

const vouchersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VOUCHERS_UPDATE': {
      return action.vouchers;
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.vouchers) return action.vouchers;

      return state;
    }
    default:
      return state;
  }
};

export default vouchersReducer;
