import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { stateContext } from './Contexts/State';

// there were problems with the google tag for goboat italy
// they wanted to try adding the tag directly
// im skeptical but still did it
const UkConvertTag = () => {
  const { state } = useContext(stateContext);

  if (
    window.location.hostname !== 'booking.goboat.co.uk' &&
    (!state.shop || state.shop.country !== 'United Kingdom')
  ) {
    return null;
  }

  return (
    <Helmet>
      <script
        type="text/javascript"
        src="//cdn-4.convertexperiments.com/js/10048264-10049932.js"
      />
      <script>
        {`
          console.log('Fish n chips');
        `}
      </script>
    </Helmet>
  );
};

export default UkConvertTag;
