const initialState = {
  customer: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    birthDate: '',
    pin: '',
    address: {
      country: '',
    },
  },
  formValid: false,
  pristine: true,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER_UPDATE_CUSTOMER': {
      return {
        ...state,
        customer: action.customer,
      };
    }
    case 'CUSTOMER_UPDATE_FORM_VALID': {
      return {
        ...state,
        formValid: action.formValid,
      };
    }
    case 'CUSTOMER_SET_PRISTINE': {
      return {
        ...state,
        pristine: action.pristine || false,
      };
    }
    case 'BOOKING_UPDATE_BOOKING': {
      if (action.customer) {
        return {
          ...state,
          customer: action.customer,
        };
      }

      return state;
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS': {
      return {
        ...state,
        customer: {
          ...state.customer,
          address: {
            ...state.customer.address,
            country: action.shop?.country || '',
          },
        },
      };
    }
    default:
      return state;
  }
};

export default customerReducer;
