const initialState = {
  location: false,
  boatCount: false,
  duration: false,
  booking: false,
  bookingDate: false,
  priceModel: false,
  customerForm: false,
  terms: false,
  newsletter: false,
  payment: false,
  cancelBooking: false,
  voucher: false,
  paymentIntent: false,
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ERROR_LOCATION_MISSING': {
      return {
        ...state,
        location: action.error,
      };
    }
    case 'ERROR_BOATCOUNT_MISSING': {
      return {
        ...state,
        boatCount: action.error,
      };
    }
    case 'ERROR_DURATION_MISSING': {
      return {
        ...state,
        duration: action.error,
      };
    }
    case 'ERROR_DATE_MISSING': {
      return {
        ...state,
        date: action.error,
      };
    }
    case 'ERROR_TIME_MISSING': {
      return {
        ...state,
        time: action.error,
      };
    }
    case 'ERROR_BOOKING_CREATE': {
      return {
        ...state,
        booking: action.error,
      };
    }
    case 'ERROR_BOOKING_DATE': {
      return {
        ...state,
        bookingDate: action.error,
      };
    }
    case 'ERROR_PRICE_MODEL': {
      return {
        ...state,
        priceModel: action.error,
      };
    }
    case 'ERROR_CUSTOMER_FORM': {
      return {
        ...state,
        customerForm: action.error,
      };
    }
    case 'ERROR_TERMS': {
      return {
        ...state,
        terms: action.error,
      };
    }
    case 'ERROR_NEWSLETTER': {
      return {
        ...state,
        newsletter: action.error,
      };
    }
    case 'ERROR_PAYMENT': {
      return {
        ...state,
        payment: action.error,
      };
    }
    case 'ERROR_CANCEL_BOOKING': {
      return {
        ...state,
        cancelBooking: action.error,
      };
    }
    case 'ERROR_VOUCHER': {
      return {
        ...state,
        voucher: action.error,
      };
    }
    case 'ERROR_PAYMENT_INTENT': {
      return {
        ...state,
        paymentIntent: action.error,
      };
    }
    case 'BOOKING_UPDATE_BOAT_COUNT':
    case 'GIFTCARD_UPDATE_BOAT_COUNT': {
      return {
        ...state,
        boatCount: false,
      };
    }
    case 'BOOKING_UPDATE_DURATION':
    case 'GIFTCARD_UPDATE_DURATION': {
      return {
        ...state,
        duration: false,
      };
    }
    case 'BOOKING_UPDATE_DATE': {
      return {
        ...state,
        booking: false,
      };
    }
    case 'SHOP_UPDATE_SHOP': {
      return {
        ...state,
        location: false,
      };
    }
    case 'TERMS_UPDATE_TERMS_CHECKED': {
      return {
        ...state,
        terms: false,
      };
    }
    case 'TERMS_UPDATE_NEWSLETTER_SUBSCRIBED': {
      return {
        ...state,
        newsletter: false,
      };
    }
    case 'VOUCHER_UPDATE_IS_OPEN':
    case 'VOUCHER_UPDATE_CODE': {
      return {
        ...state,
        voucher: false,
      };
    }
    case 'PAYMENT_INTENT_SET_CLIENT_SECRET': {
      return {
        ...state,
        paymentIntent: false,
      };
    }
    default:
      return state;
  }
};

export default errorReducer;
