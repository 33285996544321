const initialState = {
  current: 1,
  total: 4,
  progress: (1 / 4) * 100,
};

const stepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'STEPS_UPDATE_CURRENT': {
      return {
        ...state,
        current: action.current,
        progress: (action.current / state.total) * 100,
      };
    }
    case 'STEPS_UPDATE_TOTAL': {
      return {
        ...state,
        total: action.total,
        progress: (state.current / action.total) * 100,
      };
    }
    default:
      return state;
  }
};

export default stepsReducer;
