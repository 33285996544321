const initialState = [];

const itemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ITEMS_UPDATE_ITEMS': {
      return action.items;
    }
    case 'SHOP_UPDATE_SHOP':
    case 'SHOP_FETCH_SHOP_SUCCESS':
    case 'SHOP_FETCH_SHOP_SUCCESS_KEEP_BOOKING': {
      const publicItems = [];
      if (action.shop.items) {
        for (const item of action.shop.items) {
          if (!item.archived) {
            publicItems.push(item);
          }
        }
      }

      return publicItems;
    }
    default:
      return state;
  }
};

export default itemsReducer;
