const initialState = {
  loading: false,
  error: false,
  shops: [],
};

const shopsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOPS_FETCH_SHOPS_LOADING': {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case 'SHOPS_FETCH_SHOPS_SUCCESS': {
      // Filter out hidden shops was previously done in the api call to the legacy api
      let visibleShops = [];

      if (action.shops.length > 0) {
        visibleShops = action.shops.filter((shop) => !shop.hidden);
      }

      return {
        ...state,
        loading: false,
        error: false,
        shops: visibleShops,
      };
    }
    case 'SHOPS_FETCH_SHOPS_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};

export default shopsReducer;
