const initialState = {
  isOpen: false,
  code: '',
};

const voucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VOUCHER_UPDATE_IS_OPEN': {
      return {
        ...state,
        isOpen: action.isOpen,
        code: '',
      };
    }
    case 'VOUCHER_UPDATE_CODE': {
      return {
        ...state,
        code: action.code,
      };
    }
    default:
      return state;
  }
};

export default voucherReducer;
