import localizations from '../localizations';
import { getLang } from '../Utils/locale';
import { parse } from 'query-string';

const lang = getLang(parse(window.location.search).lang);

const initialState = {
  lang,
  localization: localizations[lang],
};

const localeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOCALE_UPDATE_LANG': {
      return {
        lang: action.lang,
        localization: localizations[action.lang],
      };
    }
    default:
      return state;
  }
};

export default localeReducer;
